<template>
  <div class="page_box" v-loading="loading">
    <div class="page_title">员工薪资</div>
    <div class="form_box" v-for="(item,index) in column_group" :key="index">
      <div class="form_title">
        <span>></span>
        <span>{{ item.parent_title }}</span>
      </div>
      <el-form
        class="form"
        label-width="130px">
        <el-form-item :label="item2.title" v-for="(item2,index2) in item.child" :key="index2">
          <el-input clearable v-model="(detial[item2.key]||{})[item2.key2]" v-if="item2.key2" disabled></el-input>
          <el-input clearable v-model="detial[item2.key]" v-if="!item2.key2" disabled></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class="form_box" v-for="(item,index) in column_group2" :key="item.parent_title">
      <div class="form_title">
        <span>></span>
        <span>{{ item.parent_title }}</span>
      </div>
      <el-form
        class="form2"
        label-width="130px"
        
      >
        <el-form-item :label="item2.title" v-for="(item2,index2) in item.child" :key="index2">
          <el-input type="number" clearable v-model.number="(detial[`fee_${item2.id}`]||{}).money"></el-input>

        </el-form-item>
      </el-form>
    </div>
    <div class="page_btns">
      <el-button type="primary" @click="do_submit()">提交</el-button>
      <el-button @click="$router.back()">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading:false,
      form: {
        name: "",
      },
      column_group: [
        {
          parent_title: "基本信息",
         
          child: [
            {
              key: "user",
              key2:'name',
              title: "姓名：",
            },
            {
              key: "user",
              key2:'depart_title',
              title: "部门：",
            },
            {
              key: "user",
              key2:'level6',
              title: "岗位：",
            },
            {
              key: "atten_day",
              title: "出勤：",
            },
          ],
        },
       
      ],
      column_group2:[],
      detial:{},
    };
  },
  computed: {},
  created() {
    this.init();
    this.get_detial()
  },
  methods: {
    // 初始化表单
    init() {
      // this.column_group.forEach((v) => {
      //   v.child.forEach((v2) => {
      //     this.$set(v2, "model", "");
      //     this.$set(v2, "disabled", false);
      //   });
      // });
      this.loading = true;
				this.$api("feeProjectList", {
					// mobile:this.mobile
					...this.forms,
					...this.pages,
				},"post").then((res) => {
					this.loading = false;
					if (res.code == 200) {
						console.log('配置列表',res)
						let obj = {};
						let list=res.data
						list.forEach((item, index) => {
							item.ac_show=true
							// this.$set(item,'ac_show',true)
							let { parent_title } = item;
							if (!obj[parent_title]) {
								obj[parent_title] = {
									parent_title,
									child: [],//全部的
									
								}
							}
							obj[parent_title].child.push(item);
						});
						let data = Object.values(obj);
						console.log('结果',data)
            
						this.column_group2=data
						

					} else {
						alertErr(res.msg);
					}
				});
    },
    get_detial(){
      this.loading = true;
				this.$api("empSalaryDetail", {
          id:this.$route.query.id
				},"post").then((res) => {
					this.loading = false;
					if (res.code == 200) {
						console.log('详情',res)
						this.detial=res.data
					} else {
						alertErr(res.msg);
					}
				});
    },
		
		//上报
		do_submit() {
      let objs={}
			this.column_group2.forEach((e)=>{
        e.child.forEach((v)=>{
          this.$set(objs,`fee_${v.id}`,this.detial[`fee_${v.id}`].money)
        })
        // objs[`fee_${e.id}`]=1
      })
      console.log(objs)
      this.loading = true;
				this.$api("empSalaryEdit", {
          id:this.$route.query.id,
          ...objs
				},"post").then((res) => {
					this.loading = false;
					if (res.code == 200) {
						console.log('详情',res)
            this.$router.back()
					} else {
						alertErr(res.msg);
					}
				});

		}
  },
};
</script>

<style lang="less" scoped>
.page_box {
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #e6e6e6;
  padding: 19px 17px;
  .page_title {
    font-weight: bold;
    font-size: 14px;
    color: #333333;
  }
  .form_box { 
    margin-top: 46px;
    .form_title {
      width: 100%;
      text-align: left;
      padding-bottom: 17px;
      border-bottom: 1px solid #c0c0c0;
      span {
        font-weight: bold;
        font-size: 14px;
        color: #333333;
      }
      span:nth-child(1) {
        padding-right: 4px;
      }
    }
    .form {
      margin-top: 36px;
      // display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      /deep/.el-form-item{
        width: 50%;
      }
      .el-input__inner {
        width: 545px;
        height: 40px;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #d4d4d4;
      }
    }
    .form2{
      margin-top: 36px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      /deep/.el-form-item{
        width: 50%;
      }
      .el-input__inner {
        width: 545px;
        height: 40px;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #d4d4d4;
      }
    }
  }
}
.page_btns {
  width: 100%;
  display: flex;
  justify-content: center;
  .el-button {
    width: 179px;
  }
}
</style>